/**
 * @file It contains scripts for Forgot Password page
 */
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ForgotPasswordPage",
  data() {
    return {
      email: undefined
    }
  },
  computed: {
    ...mapGetters({
      "clientName"               : "auth/clientName",
      "isRequestingPasswordReset": "auth/isRequestingPasswordReset",
      "isPasswordResetRequested" : "auth/isPasswordResetRequested"
    })
  },
  methods: {
    ...mapActions({
      "forgotPassword": "auth/forgotPassword",
      "notify"        : "shared/notify"
    })
  },
  watch: {
    isPasswordResetRequested: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "425"
          })
          this.$router.back()
        }
      }
    }
  }
}